<template>
  <div class="card p-3">
    <h2 class="text-xl font-semibold">Top đóng góp bản thu</h2>
    <table v-if="topUpload.data && topUpload.data.length" class="mt-3 w-full">
      <tbody>
      <top-upload-item :item="item" :index="5 * (topUpload.paginatorInfo.currentPage - 1) + index + 1"
                      v-for="(item, index) in topUpload.data"
                      :key="'topUploads' + index"/>
      </tbody>
    </table>
    <div class="mt-4">
      <v-pagination
          class="justify-content-end"
          v-if="topUpload.paginatorInfo.lastPage > 1"
          v-model="topUpload.paginatorInfo.currentPage"
          :pages="topUpload.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadTopUpload"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import TopUploadItem from "./TopUploadItem";

export default {
  name: "TopUpload",
  components: {TopUploadItem},
  props: {
    showPaging: Boolean
  },
  data() {
    return {
      topUpload: {
        data: [],
        paginatorInfo: {
          total: 0,
          lastPage: 0,
          currentPage: 1
        }
      }
    }
  },
  methods: {
    loadTopUpload() {
      let query = `query($page: Int) {
        topUpload(first: 5, page: $page) {
          paginatorInfo {
            total
            lastPage
            currentPage
          }
          data {
            username
            avatar
            user_id
            total
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.topUpload.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.topUpload) {
              this.topUpload = data.data.topUpload;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  mounted() {
    this.loadTopUpload();
  }
}
</script>
