<template>
  <tr class="">
    <td class="px-2 py-2 text-emerald-600 font-medium">
      <span :class="'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full ' + getBgClass(index)">{{ index }}</span>
    </td>
    <td class="px-2 py-2 text-emerald-600 font-medium flex items-center">
      <router-link :to="{name: 'UserDetail', params: {id: item.id}}" class="mr-1">
        <img
            :src="item.avatar ? item.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg'"
            :alt="item.username" class="w-10 h-10 rounded-full object-cover mr-2">
      </router-link>
      <router-link :to="{name: 'UserDetail', params: {id: item.id}}">
        {{ item.username }}
      </router-link>
    </td>
    <td class="px-2 py-2 text-emerald-600 font-medium">
      +{{ thousandFormat(item.point) }}
    </td>
  </tr>
</template>

<script>
import {thousandFormat} from "../../core/services/utils.service";

export default {
  name: "TopPointItem",
  props: {
    index: Number,
    item: {
      type: Object,
      default() {
        return {
          id: "",
          username: "",
          avatar: "",
          point: "",
        };
      }
    }
  },
  methods: {
    getBgClass(index) {
      switch (index) {
        case 1:
          return 'bg-pink-800';
        case 2:
          return 'bg-pink-600';
        case 3:
          return 'bg-pink-400';
        default:
          return 'bg-pink-400';
      }
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  }
}
</script>
