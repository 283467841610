<template>
  <div class="card p-3">
    <h2 class="text-xl font-semibold">Top cống hiến</h2>
    <table v-if="topPoints.data && topPoints.data.length" class="mt-3 w-full">
      <tbody>
      <top-point-item :item="item" :index="5 * (topPoints.paginatorInfo.currentPage - 1) + index + 1"
                      v-for="(item, index) in topPoints.data"
                      :key="'topPoints' + index"/>
      </tbody>
    </table>
    <div class="mt-4">
      <v-pagination
          class="justify-content-end"
          v-if="topPoints.paginatorInfo.lastPage > 1"
          v-model="topPoints.paginatorInfo.currentPage"
          :pages="topPoints.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadTopPoint"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import TopPointItem from "./TopPointItem";

export default {
  name: "TopPoint",
  components: {TopPointItem},
  props: {
    showPaging: Boolean
  },
  data() {
    return {
      topPoints: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadTopPoint() {
      let query = `query($page: Int) {
        users(page: $page, first:5, orderBy: [{column: "point", order: DESC}], where: {AND: [{column: "point", value: 0, operator: GT}, {column: "id", value: 1, operator: NEQ}]}) {
          data {
            id
            username
            avatar {
              url
            }
            point
          }
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.topPoints.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.topPoints = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadTopPoint();
  }
}
</script>
